
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
    client: Object,
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  watch: {
  },
  computed: {
    company(){
      return this.$store.state.auth.company
    },
    subsc_type_text(){
      return this.company.allow_subsc_rule_to_client ? this.client.subsc_type_text : this.company.subsc_type_text
    },
    subsc_type(){
      return this.company.allow_subsc_rule_to_client ? this.client.subsc_type : this.company.subsc_type
    },
    subsc_base_date(){
      return this.company.allow_subsc_rule_to_client ? this.client.subsc_base_date : this.company.subsc_base_date
    },
    current_cart(){
      return this.client.subsc_settings.current_cart
    },
    payment_method(){
      const payment_method = _.find(this.payment_methods, ["id", this.client.payment_method_id])
      return payment_method ? payment_method.name : "オンラインカード"
    },
  },
  methods: {
  },
}
