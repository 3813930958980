
import Common from '~/mixins/common'
import _ from 'lodash';
import { nextSubscTicketEndStyles } from '~/utils/models'
import ClPaymentItemSelector from '~/components/common/cl_payment/ItemSelector'

export default {
  mixins: [Common],
  components: {
    ClPaymentItemSelector,
  },
  props: {
    useSubscriptions: {
      type: Boolean,
      default: false
    },
    courseEditable: {
      type: Boolean,
      default: true
    },
    reserve_type: String,
    cart: Object,
  },
  data() {
    return {
      edit_cart: null,
      nextSubscTicketEndStyles: nextSubscTicketEndStyles,
      price_sum: 0,
    }
  },
  async mounted() {
    let self = this
    this.edit_cart = _.cloneDeep(this.cart)
    this.calculatePriceSum()
  },
  watch: {
    cart: {
      handler: function (val) {
        this.edit_cart = _.cloneDeep(val)
        this.calculatePriceSum()
      },
      deep: true
    },
  },
  computed: {
    next_price_sum(){
      let price_sum = this.edit_cart.course_1.price
      this.edit_cart.orders.forEach(order => {
        if(order.is_subscription) price_sum += order.sub_total
      })
      return price_sum
    },
    hasMinusPrice(){
      return this.edit_cart.price_sum < 0 || this.next_price_sum < 0
    },
  },
  methods: {
    addDiscount(rate){
      this.$bvModal.msgBoxConfirm('コースの割引を追加してよろしいですか？', {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          let cartItems = _.cloneDeep(this.edit_cart.orders)
          const price = parseInt(this.edit_cart.course_1.price * rate) * -1
          cartItems.push({
            is_subscription: false,
            id: null,
            item_category_id: null,
            name: `コース初回割引`,
            description: null,
            price: price,
            count: 1,
            sub_total: price * 1,
            tax_rate: 10,
          })
          this.cartUpdate(cartItems)
        }
      })
    },

    changeSubscCourse(){
      let course = _.find(this.courses, ["id", this.edit_cart.course_1.course_id])
      console.log("changeSubscCourse", course)
      if(course){
        this.edit_cart.course_1.name = course.name
        this.edit_cart.course_1.ticket_number = course.ticket_number
        this.edit_cart.course_1.price = course.price
        this.edit_cart.course_1.next_subsc_ticket_end_style = course.next_subsc_ticket_end_style
        this.edit_cart.course_1.expire_days = course.expire_days
        this.edit_cart.course_1.able_menu_ids = course.able_menu_ids
      }else{
        this.edit_cart.course_1.name = null
        this.edit_cart.course_1.ticket_number = 0
        this.edit_cart.course_1.price = 0
        this.edit_cart.course_1.next_subsc_ticket_end_style = "now_to_limit"
        this.edit_cart.course_1.expire_days = 0
        this.edit_cart.course_1.able_menu_ids = []
      }
      this.emitChange()
    },

    // オプションの設定
    cartUpdate(cartItems){
      console.log("cartUpdate", cartItems)
      this.edit_cart.orders = _.cloneDeep(cartItems)
      this.emitChange()
    },

    calculatePriceSum(){
      const optionSubTotal = _.sumBy(this.edit_cart.orders, 'sub_total')
      this.edit_cart.price_sum = this.edit_cart.course_1.price + optionSubTotal
    },

    // 変更をEmit
    emitChange(){
      this.calculatePriceSum()
      this.$emit("change", this.edit_cart)
    },

  },
}
