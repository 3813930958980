import _ from 'lodash'
import dayjs from 'dayjs'
import { planTypeOptions } from '~/utils/models'

const addBlankToSelect = function (arr) {
  arr = _.cloneDeep(arr)
  arr.unshift({ name: '--- 選んでください ---', id: null })
  return arr
}

let truncate = function (value, length) {
  if (value === null) return ''
  var length = length ? parseInt(length) : 20
  var ommision = '...'

  if (value.length <= length) {
    return value
  }

  return value.substring(0, length) + ommision
}

let getInvertedColor = function (colorCode) {
  try {
    // 16進数を変換
    const red = parseInt(colorCode.substr(1, 2), 16)
    const green = parseInt(colorCode.substr(3, 2), 16)
    const blue = parseInt(colorCode.substr(5, 2), 16)
    // 明るさを判定
    const luminance = (red * 299 + green * 587 + blue * 114) / 2550
    // 明るいときは黒、暗いときは白を返す（60の基準値は調整する）
    if (luminance > 60) {
      return '#000000'
    } else {
      return '#ffffff'
    }
  } catch (e) {
    return '#000000'
  }
}

// 5分間書くでの時間セレクト
let timeOptions = function () {
  let timeOptions = []
  for (let h = 0; h <= 24; h++) {
    for (let m = 0; m < 60; m += 5) {
      if (!(h === 24 && m > 0)) {
        let time = `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`
        timeOptions.push({
          value: time,
          text: time
        })
      }
    }
  }
  return timeOptions
}

// 5分間書くでの時間セレクト
let hourOptions = function () {
  let hourOptions = []
  for (let h = 0; h <= 24; h++) {
    ;[0, 30].forEach(m => {
      if (!(h === 24 && m === 30)) {
        let time = `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`
        hourOptions.push({
          value: time,
          text: time
        })
      }
    })
  }
  return hourOptions
}

// 数字を含む名前順
// https://zenn.dev/ymkn8crz/articles/ff39b43c4defc3
let numberNameSort = function (arr, attr) {
  return _.sortBy(arr, attr)
  // return arr.sort((a,b) => {
  //   return eval(`parseInt(a.${attr}) - parseInt(b.${attr})`)
  // })
}

let scrollToTop = function (isModal = false) {
  if(isModal){
    let elements = document.getElementsByClassName('modal-body')
    elements.forEach(el => {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    })  
  }else{
    document.getElementById('__layout').scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })  
  }
}

let getZoomMessage = function (lesson) {
  let message = `トピック: ${lesson.zoom_settings.topic}
時間: ${dayjs(lesson.zoom_settings.start_time).format('YYYY-MM-DD HH:mm:ss')} 大阪、札幌、東京

Zoomミーティングに参加する
${lesson.zoom_settings.join_url}

ミーティングID: ${lesson.zoom_settings.id}
パスコード: ${lesson.zoom_settings.password}
`
  return message
}

let comparisonHm = function (hm_1, diff, hm_2) {
  time_1 = dayjs(`2023/1/1 ${hm_1}`)
  time_2 = dayjs(`2023/1/1 ${hm_2}`)
  if (diff === '>') return time_1.isBefore(time_2)
  if (diff === '>=') return time_1.isSameOrBefore(time_2)
  if (diff === '==') return time_1.isSame(time_2)
  if (diff === '<=') return time_2.isBefore(time_1)
  if (diff === '<') return time_2.isSameOrBefore(time_1)
}

let sleep = second => new Promise(resolve => setTimeout(resolve, second * 1000))

let reserveTypeText = reserve_type => {
  let option = _.find(planTypeOptions, ['value', reserve_type])
  if (option) {
    return option.text
  } else {
    return '-'
  }
}

export {
  addBlankToSelect,
  truncate,
  getInvertedColor,
  timeOptions,
  hourOptions,
  numberNameSort,
  scrollToTop,
  getZoomMessage,
  comparisonHm,
  sleep,
  reserveTypeText,
}
