
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import Masters from '~/mixins/masters'
import { scrollToTop } from '~/utils/tool'
import TicketSetNew from '~/components/common/ticket_set/New'
import { form_config } from '~/utils/models'

export default {
  mixins: [Common,Masters],
  components: {
    TicketSetNew,
  },
  props: {
    client: {type: Object, default: null},
    filter: {type: String, default: "company"},
    showClient: {type: Boolean, default: false},
  },
  data() {
    return {
      // data
      uuid_eq: null,
      freeword: null,
      status_eq_any: [],
      statusOptions: [
        { text: '有効', value: "active" },
        { text: '無効', value: "disactive" },
      ],
      created_at_gteq: null,
      created_at_lteq_end_of_day: null,
      use_from_gteq: null,
      use_from_lteq_end_of_day: null,
      use_to_gteq: null,
      use_to_lteq_end_of_day: null,
      company_id_eq: null,
      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
      currentTicketSets: [],

      // edit date limit
      selectedTicketSet: null,
      showDateLimitModal: false,

      // import
      showImport: false,
      importFile: null,
    }
  },
  async mounted() {
    this.search()    
  },
  watch: {
    reload_at (val, old) {
      this.search()
    },
    cl_payment_at (val, old) {
      console.log("list cl_payment_at")
      this.search()
    }
  },  
  computed: {
  },
  methods: {
    ableMenus(ticket_set){
      return _.filter(this.menus, (c) => {
        return ticket_set.able_menu_ids.includes(c.id)
      })
    },

    // 有効期限
    ticketSetEditDateLimit(ticket_set){
      // 同一店舗かどうか
      if(!this.isSameCompany){
        window.storeCtl.commit("alert/setError", "他店舗のチケットは編集できません")
        return
      }
      this.selectedTicketSet = _.cloneDeep(ticket_set)
      this.showDateLimitModal = true
    },
    async ticketSetUpdateDateLimit(){
      let self = this
      self.$bvModal.msgBoxConfirm("有効期間を更新してよろしいですか？", {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
          .put(`/cmp/floor/ticket_sets/${self.selectedTicketSet.uid}/update_limit_date.json`, {
            use_from: self.selectedTicketSet.use_from,
            use_to: self.selectedTicketSet.use_to,
          })
            .then(async (res) => {
              window.storeCtl.commit("loading/stop")
              if (res.data.status === 'success') {
                window.storeCtl.commit("event/updateClPayment")
                await self.search()
                self.$emit("canceled")
                self.showDateLimitModal = false
                window.storeCtl.commit("alert/setSuccess", "処理しました")
              } else {
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },

    // 無効化
    ticketSetCancel(ticket_set){
      let self = this
      
      self.$bvModal.msgBoxConfirm("（注）チケットの無効化をしても支払いは自動払い戻しされません。返金したい場合は別途支払いを払い戻しして下さい。また利用済みのチケットで予約にはチケットを無効化しても影響ありません。", {
        title: '無効化してよろしいですか？',
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
          .delete(`/cmp/floor/ticket_sets/${ticket_set.uid}.json`)
            .then(async (res) => {
              window.storeCtl.commit("loading/stop")
              if (res.data.status === 'success') {
                window.storeCtl.commit("event/updateClPayment")
                await self.search()
                self.$emit("canceled")
                window.storeCtl.commit("alert/setSuccess", "処理しました")
              } else {
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },

    // import
    newImport(){
      this.importFile = null
      this.showImport = true
    },
    async submitImportFile(){
      let self = this
      self.$store.commit("loading/start")
      const formData = new FormData();
      formData.append(`file`, this.importFile)
      await httpClient
        .post(`/cmp/floor/ticket_sets/import.json`, formData, form_config)
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            window.storeCtl.commit("loading/stop")
            self.showImport = false
            window.storeCtl.commit("alert/setSuccess", "処理を受付ました。結果はデータ処理一覧または結果メールにて確認いただけます。")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
    
    // 検索
    async clear() {
      this.freeword = null
      this.status_eq_any = []
      this.created_at_gteq = null
      this.created_at_lteq_end_of_day = null
      this.use_from_gteq = null
      this.use_from_lteq_end_of_day = null
      this.use_to_gteq = null
      this.use_to_lteq_end_of_day = null
      this.company_id_eq = null
      this.order = "desc"
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async autoSearch(){
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async search() {
      let self = this

      // set params
      let params = {
        "q[company_id_eq]": self.company_id_eq,
        "q[uid_or_client_name_or_client_uid_cont]": self.freeword,
        "q[created_at_gteq]": self.created_at_gteq,
        "q[created_at_lteq_end_of_day]": self.created_at_lteq_end_of_day,
        "q[use_from_gteq]": self.use_from_gteq,
        "q[use_from_lteq_end_of_day]": self.use_from_lteq_end_of_day,
        "q[use_to_gteq]": self.use_to_gteq,
        "q[use_to_lteq_end_of_day]": self.use_to_lteq_end_of_day,
        "status_eq_any": self.status_eq_any,
        client_id: self.client ? self.client.id : null,
        page: self.pages.current_page,
        order: self.order,
        current_company: self.filter === "company" ? true : false,
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/ticket_sets.json', {params: params})
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.currentTicketSets = res.data.data.ticket_sets
          self.pages = res.data.data.pages
          scrollToTop()
          self.$emit("changeSearch", res.data.data.pages)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
  }
}
