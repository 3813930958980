
import Common from '~/mixins/common'
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { client_cart, subsc_course_settings, planTypeOptions, listExtendForMonthlyOptions, nextSubscTicketEndStyles, cancelTypes } from '~/utils/models'
import DocumentInputs from '~/components/util/docs/DocumentInputs'
import ImageFileInput from '~/components/util/ImageFileInput'
import ClientCart from '~/components/common/client/Cart'

export default {
  mixins: [Common],
  components: {
    DocumentInputs,
    ImageFileInput,
    ClientCart,
  },
  props: {
  },
  data() {
    return {
      showModal: false,
      course_id: null,
      edit_client: null,
      edit_course: null,

      // 月回数制
      stock_lesson_size: 0,
      stock_lesson_size_next: 0,
      stock_lesson_size_next2: 0,

      // 予約ルール設定
      planTypeOptions: planTypeOptions,
      listExtendForMonthlyOptions: listExtendForMonthlyOptions,
      handleChangeTicket: false,

      nextSubscTicketEndStyles: nextSubscTicketEndStyles,
      cancelTypes: cancelTypes,

      // 課金カートの中身
      payment_method_id: null,
      edit_cart: null,
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
    currentCourse(){
      if(!this.edit_client) return null
      return _.find(this.courses, ["id", this.edit_client.course_id])
    },
    // このコースに人が購入可能なコース
    thisCourseByableCourses(){
      // use_new_versionかつreserve_typeが同じもの
      return _.filter(this.courses, (c) => {
        return c.use_new_version === true && c.reserve_type === this.edit_course.reserve_type
      })
    },
  },
  methods: {
    copyToEveryMonth(){
      this.stock_lesson_size = this.edit_course.monthly_stock_lesson_size
      this.stock_lesson_size_next = this.edit_course.monthly_stock_lesson_size
      this.stock_lesson_size_next2 = this.edit_course.monthly_stock_lesson_size
    },

    async openClientCourse(client){
      this.course_id = null
      this.edit_course = null
      this.stock_lesson_size = 0
      this.stock_lesson_size_next = 0
      this.stock_lesson_size_next2 = 0
      this.edit_cart = _.cloneDeep(client_cart)
      await httpClient
        .get(`/cmp/floor/clients/${client.uid}.json`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            this.edit_client = res.data.data.client
            this.payment_method_id = res.data.data.client.payment_method_id
            this.showModal = true
          }else{
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    // コースを選択した際に実行
    changeCourse(){
      this.edit_course = _.cloneDeep(_.find(this.courses, ["id", this.course_id]))
      if(this.edit_course.reserve_type === "monthly_stock"){
        this.stock_lesson_size = this.edit_course.monthly_stock_lesson_size
        this.stock_lesson_size_next = this.edit_course.monthly_stock_lesson_size
        this.stock_lesson_size_next2 = this.edit_course.monthly_stock_lesson_size
      }else{
        this.stock_lesson_size = 0
        this.stock_lesson_size_next = 0
        this.stock_lesson_size_next2 = 0
      }
      // 課金カートの初期化
      this.edit_cart = _.cloneDeep(client_cart)
      this.edit_cart.name = this.edit_course.name
      let course_1 = _.cloneDeep(subsc_course_settings)
      course_1.course_id = this.edit_course.id
      course_1.name = this.edit_course.name
      course_1.ticket_number = this.edit_course.ticket_number
      course_1.price = this.edit_course.price
      course_1.next_subsc_ticket_end_style = this.edit_course.next_subsc_ticket_end_style
      course_1.expire_days = this.edit_course.expire_days
      course_1.able_menu_ids = this.edit_course.able_menu_ids
      this.edit_cart.course_1 = course_1
      this.edit_cart.orders = this.edit_course.subsc_settings.orders
      this.edit_cart.price_sum = this.edit_course.price + _.sumBy(this.edit_cart.orders, 'sub_total')
    },
    async update(){
      let self = this
      self.$store.commit("loading/start")
      await httpClient
        .put(`/cmp/floor/clients/${self.edit_client.uid}/change_course.json`, {
          course: this.edit_course,
          course_id: this.course_id,
          stock_lesson_size: this.stock_lesson_size,
          stock_lesson_size_next: this.stock_lesson_size_next,
          stock_lesson_size_next2: this.stock_lesson_size_next2,
          payment_method_id: this.payment_method_id,
          cart: this.edit_cart,
        })
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            window.storeCtl.commit("company/setClient", res.data.data.client)
            self.showModal = false
            self.$emit("updated", res.data.data.client)
            window.storeCtl.commit("alert/setSuccess", "更新しました")
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
  },
}
