
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import { mapState } from 'vuex'
import Tabs from '~/components/util/Tabs'

export default {
  components: {
    Tabs,
  },
  data() {
    return {
      showSelectModal: false,
      showLoginModal: false,

      isBeforeRequest: true,
      staff_uid: null,
      password: null,
      confirm_token: null,
      showPassword: false,

      tabIndex: 0,
      tabOptions: [
      {label: "店舗切り替え", value: 0, disabled: false},
      {label: "アカウント切り替え", value: 1, disabled: false},
      ],
    }
  },
  computed: {
    ...mapState({
      company: state => state.auth.company,
      able_companies: state => state.devise.able_companies,
    }),
  },
  methods: {
    // モーダル表示
    open(){
      this.showSelectModal = true
    },
    // タブ切り替え
    changeTab(event){
      this.tabIndex = event.newTabIndex
    },
    async companyChange(able_company){
      let self = this
      // 自分には移動できない
      if(able_company.uid === this.company.uid) return
      self.$store.commit("loading/start", true)
      // 1.既存店舗の情報にカレンダーIDを追加
      // 2.Auth情報を切り替え
      await httpClient
        .post('/cmp/account/company_change.json', {
          company_uid: able_company.uid,
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            // 3.Authセット
            self.$store.commit('auth/setAuthToken', res.data.data.auth_token)
            // よく使うクライアント履歴をリセット
            self.$store.commit("auth/clearLatestClients")
            // // カレンダーIDを移植
            // window.storeCtl.commit("auth/setCalendarIds", account.calendar_ids)
            // Firebase
            window.storeCtl.dispatch("fireStore/init", true)
            // リロード
            location.reload();
          } else {
            self.$store.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          self.$store.commit("loading/stop")
        })
    },
    async accountChange(account){
      let self = this
      // 自分には移動できない
      if(account.staff_uid === this.$store.state.auth.staff.uid) return
      self.$store.commit("loading/start", true)
      // 現状のカレンダーを記憶
      let current_account = {
        company_uid: this.$store.state.auth.company.uid,
        company_name: this.$store.state.auth.company.name,
        staff_uid: this.$store.state.auth.staff.uid,
        staff_name: this.$store.state.auth.staff.name,
        auth_token: this.$store.state.auth.authToken,
        calendar_ids: this.$store.state.auth.calendar_ids,
      }
      self.$store.commit('devise/updateAuthList', current_account)
      // アカウント変更
      self.$store.commit('auth/setAuthToken', account.auth_token)
      // よく使うクライアント履歴をリセット
      self.$store.commit("auth/clearLatestClients")
      // カレンダーIDを移植
      window.storeCtl.commit("auth/setCalendarIds", account.calendar_ids)
      // Firebase
      window.storeCtl.dispatch("fireStore/init", true)
      // リロード
      location.reload();
    },
    accountLogout(account){
      let self = this
      self.$bvModal.msgBoxConfirm(`${account.staff_name}(${account.company_name})からログアウトしてよろしいですか？`, {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          this.$store.commit("devise/deleteAuthList", account)
        }
      })
    },
    accountAddStart(){
      if(this.$store.state.devise.authLists.length >= 20){
        this.$store.commit("alert/setError", "同時ログインの上限に達しているため、アカウントを減らしてください")
        return
      }
      this.isBeforeRequest = true
      this.staff_uid = null
      this.password = null
      this.confirm_token = null
      this.showPassword = false
      this.showLoginModal = true
    },
    async accountAdd(){
      let self = this
      if(this.staff_uid === this.$store.state.auth.staff.uid){
        self.$store.commit("alert/setError", "自分へのログインはできません")
        return
      }
      await self.$refs.observer.validate().then(async result => {
        self.$nuxt.$loading.start()
        self.$store.commit("loading/start", true)
        await httpClient
          .post('/cmp/account/login.json', {
            uid: self.staff_uid,
            password: self.password,
            confirm_token: self.confirm_token,
            devise_token: self.$store.state.devise.devise_token,
          })
          .then(async (res) => {
            if (res.data.status === 'success') {
              let account = {
                company_uid: res.data.data.company.uid,
                company_name: res.data.data.company.name,
                staff_uid: res.data.data.staff.uid,
                staff_name: res.data.data.staff.name,
                auth_token: res.data.data.auth_token,
                calendar_ids: [],
              }
              self.$store.commit("devise/addAuthList", account)
              // アカウント変更
              self.$store.commit('auth/setAuthToken', account.auth_token)
              // よく使うクライアント履歴をリセット
              self.$store.commit("auth/clearLatestClients")
              // カレンダーIDを移植
              window.storeCtl.commit("auth/setCalendarIds", account.calendar_ids)
              // Firebase
              window.storeCtl.dispatch("fireStore/init", true)
              // リロード
              location.reload();
            } else if(res.data.status === "devise_not_authorize"){
              self.$store.commit("loading/stop")
              self.isBeforeRequest = false
            } else {
              self.$store.commit("loading/stop")
              self.$store.commit("alert/setError", res.data.message)
            }
          })
          .finally(() => {
            this.$nuxt.$loading.finish()
          })
      });

    },
  }
}
