
import _ from 'lodash';
import { convert, stringToCode } from "encoding-japanese";
import dayjs from 'dayjs';
import { httpClient } from '~/utils/axios'
import { form_config } from '~/utils/models'
import Common from '~/mixins/common'
import { scrollToTop } from '~/utils/tool'
import ClPaymentMenu from '~/components/common/cl_payment/Menu'
import ClientCart from '~/components/common/client/Cart'
import TextCopyPart from '~/components/util/TextCopyPart'

export default {
  mixins: [Common],
  components: {
    ClPaymentMenu,
    ClientCart,
    TextCopyPart,
  },
  props: {
    client: {type: Object, default: null},
    filter: {type: String, default: "company"},
  },
  data() {
    return {
      // data
      uuid_eq: null,
      freeword: null,
      status_eq_any: [],
      statusOptions: [
        { text: '支払待', value: "before" },
        { text: '支払済', value: "paid" },
        { text: 'キャンセル', value: "canceled" },
        { text: '失敗', value: "fail" },
        // { text: '回収済', value: "fix_paid" },
        // { text: '返金失敗', value: "cancel_fail" },
      ],
      payment_roles: [
        { text: '管理者', value: "staff" },
        { text: '顧客', value: "client" },
        { text: 'システム', value: "batch" },
      ],
      created_at_gteq: null,
      created_at_lteq_end_of_day: null,
      pay_at_gteq: null,
      pay_at_lteq_end_of_day: null,
      payment_method_id_eq: null,
      payment_role_eq: null,
      company_id_eq: null,

      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
      currentClPayments: [],

      // 日付選択
      selectedCreateDateOption: null,
      selectedPayDateOption: null,
      dateOptions: [
        { value: null, text: "期間指定" },
        { value: "lastMonth", text: "先月" },
        { value: "thisMonth", text: "今月" },
        { value: "thisWeek", text: "今週" },
      ],

      // cancel
      edit_cl_payment: null,
      showCancelModal: false,
      ticket_roleback: false,
      item_roleback: false,

      // collect
      action_type: null,
      showEditModal: false,
      showRequestEditModal: false,
      showHistoryModal: false,
      fail_message: null,

      // import download
      showImport: false,
      showDownload: false,
      outputType: null,
      dataTypeOptions: [
        { value: "csv", text: "CSV" },
        { value: "jaccs", text: "JACCS" },
      ],
      limit_download_count: 5000,
      importFile: null,
    }
  },
  async mounted() {
    let self = this
    this.search()
  },
  watch: {
    cl_payment_at (val, old) {
      console.log("list cl_payment_at")
      this.search()
    }
  },  
  computed: {
  },
  methods: {
  // 日付設定
  setDateRange(type) {
    // まず既存をクリア
    this.pay_at_gteq = null;
    this.pay_at_lteq_end_of_day = null;
    this.created_at_gteq = null;
    this.created_at_lteq_end_of_day = null;

    const today = dayjs();
    let fromDate, toDate;

    // どっちかの選択肢を取得
    let targetOption = this.selectedCreateDateOption;
    if (type === "pay") {
      targetOption = this.selectedPayDateOption;
    }

    switch (targetOption) {
      case "lastMonth":
        fromDate = today.subtract(1, 'month').startOf('month');
        toDate = today.subtract(1, 'month').endOf('month');
        break;
      case "thisMonth":
        fromDate = today.startOf('month');
        toDate = today.endOf('month');
        break;
      case "thisWeek":
        fromDate = today.startOf('week'); // デフォルトでは日曜始まり
        toDate = today.endOf('week');
        break;
      default:
        fromDate = null;
        toDate = null;
    }

    if (fromDate && toDate) {
      if (type === "pay") {
        this.pay_at_gteq = fromDate.format('YYYY-MM-DD');
        this.pay_at_lteq_end_of_day = toDate.format('YYYY-MM-DD');
      } else {
        this.created_at_gteq = fromDate.format('YYYY-MM-DD');
        this.created_at_lteq_end_of_day = toDate.format('YYYY-MM-DD');
      }
    }

    this.autoSearch();
  },

    // 支払待周り------------------------------
    requestEdit(cl_payment){
      this.action_type = "edit"
      this.edit_cl_payment = _.cloneDeep(cl_payment)
      this.showRequestEditModal = true
    },
    historyOpen(cl_payment){
      this.edit_cl_payment = _.cloneDeep(cl_payment)
      this.showHistoryModal = true
    },
    selectedStatusText(history){
      const payment_method = _.find(this.statusOptions, ["value", history.status])
      return payment_method ? payment_method.text : history.status === 'paied' ? "支払済" : "-"
    },
    selectedPaymentMethodText(history){
      const payment_method = _.find(this.payment_methods, ["id", history.payment_method_id])
      return payment_method ? payment_method.name : "オンライン"
    },
    memoOpen(cl_payment){
      this.action_type = "memo"
      this.edit_cl_payment = _.cloneDeep(cl_payment)
      this.showEditModal = true
    },
    collectOpen(cl_payment){
      this.action_type = "collect"
      this.edit_cl_payment = _.cloneDeep(cl_payment)
      if(this.edit_cl_payment.pay_at) this.edit_cl_payment.pay_at = dayjs(this.edit_cl_payment.pay_at).format("YYYY-MM-DD")
      this.showEditModal = true
    },
    failOpen(cl_payment){
      this.action_type = "fail"
      this.fail_message = null
      this.edit_cl_payment = _.cloneDeep(cl_payment)
      this.showEditModal = true
    },
    deleteOpen(cl_payment){
      this.action_type = "delete"
      this.edit_cl_payment = _.cloneDeep(cl_payment)
      this.$bvModal.msgBoxConfirm('支払待を削除してよろしいですか？', {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          this.updateExcute()
        }
      })
    },
    async updateExcute(){
      let self = this
      window.storeCtl.commit("loading/start")
      await httpClient
      .put(`/cmp/floor/cl_payments/${self.edit_cl_payment.uid}.json`, {
        action_type: self.action_type,
        cl_payment: self.edit_cl_payment,
        fail_message: self.fail_message,
      })
      .then(async (res) => {
        if (res.data.status === 'success') {
          this.autoSearch()
          this.showEditModal = false
          this.showRequestEditModal = false
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })

    },

    // 検索周り ------------------------------
    async clear() {
      this.freeword = null
      this.status_eq_any = []
      this.created_at_gteq = null
      this.created_at_lteq_end_of_day = null
      this.pay_at_gteq = null
      this.pay_at_lteq_end_of_day = null
      this.payment_method_id_eq = null
      this.payment_role_eq = null
      this.company_id_eq = null
      this.order = "desc"
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async autoSearch(){
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
      this.selectedCreateDateOption = null
      this.selectedPayDateOption = null
    },
    async search(){
      let self = this

      // set params
      let params = {
        "q[uid_or_name_or_memo_or_client_name_or_client_uid_or_orders_name_cont]": self.freeword,
        "q[created_at_gteq]": self.created_at_gteq,
        "q[created_at_lteq_end_of_day]": self.created_at_lteq_end_of_day,
        "q[pay_at_gteq]": self.pay_at_gteq,
        "q[pay_at_lteq_end_of_day]": self.pay_at_lteq_end_of_day,
        "q[payment_method_id_eq]": self.payment_method_id_eq,
        "q[payment_role_eq]": self.payment_role_eq,
        "q[company_id_eq]": self.company_id_eq,
        "status_eq_any": self.status_eq_any,
        client_id: self.client ? self.client.id : null,
        current_company: self.filter === "company" ? true : false,
        page: self.pages.current_page,
        order: self.order,
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/cl_payments.json', {params: params})
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.currentClPayments = res.data.data.cl_payments
          self.pages = res.data.data.pages
          scrollToTop()
          self.$emit("changeSearch", res.data.data.pages)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
    cancelConfirm(cl_payment){
      this.ticket_roleback = false
      this.item_roleback = false
      this.edit_cl_payment = cl_payment
      this.showCancelModal = true
    },
    async cancelExcute(){
      let self = this
      self.$store.commit("loading/start")
      await httpClient
      .put(`/cmp/floor/cl_payments/${this.edit_cl_payment.uid}/cancel.json`, {
        ticket_roleback: this.ticket_roleback,
        item_roleback: this.item_roleback,
      })
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            this.showCancelModal = false
            await self.search()
            self.$emit("canceled")
            window.storeCtl.commit("alert/setSuccess", "処理しました")
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
    retry(cl_payment){
      let self = this
      self.$bvModal.msgBoxConfirm("再実行していいですか？", {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
          .put(`/cmp/floor/cl_payments/${cl_payment.uid}/retry.json`)
            .then(async (res) => {
              window.storeCtl.commit("loading/stop")
              if (res.data.status === 'success') {
                await self.search()
                window.storeCtl.commit("alert/setSuccess", "処理しました")
              } else {
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },

    // 一括登録 ------------------------------
    openDownload(){
      this.outputType = "csv"
      this.showDownload = true
    },
    openImport(){
      this.outputType = "csv"
      this.importFile = null
      this.showImport = true
    },
    async execDownload(){
      let self = this
      self.$store.commit("loading/start")
      await httpClient
        .get(`/cmp/floor/cl_payments/download.json`, { params: {
          output_type: self.outputType,
          "status_eq_any": self.status_eq_any,
          "q[payment_method_id_eq]": self.payment_method_id_eq,
          "q[created_at_gteq]": self.created_at_gteq,
          "q[created_at_lteq_end_of_day]": self.created_at_lteq_end_of_day,
          "q[pay_at_gteq]": self.pay_at_gteq,
          "q[pay_at_lteq_end_of_day]": self.pay_at_lteq_end_of_day,
          "q[company_id_eq]": self.company_id_eq,
        }},{
          responseType: 'arraybuffer', // バイナリデータとして受け取る
          responseEncoding: 'shift_jis', // 文字コードを指定
        })
        .then(async (res) => {
          // * 文字コードをSJISに変換
          const unicodeList = stringToCode(res.data);
          const sjisCodeList = convert(unicodeList, "SJIS");
          const sjisData = new Uint8Array(sjisCodeList);
          // Blobデータをダウンロードする処理
          const blob = new Blob([sjisData], { type: "text/csv;charset=Shift_JIS" });
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          const fileName = `${self.outputType}_${dayjs().format('YYYYMMDDHHmmss')}.csv`;

          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          // 後処理
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("ダウンロードエラー:", error);
          window.storeCtl.commit("alert/setError", "CSVファイルのダウンロードに失敗しました");
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        });

    },
    async execImport(){
      let self = this
      self.$store.commit("loading/start")
      const formData = new FormData();
      formData.append(`file`, this.importFile)
      formData.append(`output_type`, this.outputType)
      await httpClient
        .post(`/cmp/floor/cl_payments/import.json`, formData, form_config)
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            window.storeCtl.commit("loading/stop")
            self.showImport = false
            window.storeCtl.commit("alert/setSuccess", "処理を受付ました。結果はデータ処理一覧または結果メールにて確認いただけます。")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },

  }
}
