
import { httpClient } from '~/utils/axios'
import _ from 'lodash';

export default {
  components: {
  },
  props: {
  },
  data() {
    return {
      edit_data: null,
      versions: [],
      showModal: false, 
    }
  },
  async mounted() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    async openVersion(lesson){
      let self = this
      self.edit_data = lesson
      await httpClient
      .get(`/cmp/data_versions.json?uid=${lesson.uid}&type=Lesson`)
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.versions = res.data.data.versions
          self.showModal = true
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
    },
  },
}
