
import axios from 'axios'
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
    client: Object,
  },
  data() {
    return {
      show: false,
      edit_client: null,
      accountTypeOptions: [
        { value: 1, text: '普通' },
        { value: 2, text: '当座' },
        { value: 3, text: '貯蓄' },
      ],

      // post
      post_branch_id: null,
      post_account_id: null,

      // bank search
      model: null,
      showSearch: false,
      selectedBank: null,
      searchKeyword: null,
      searchTextBank: null,
      searchBankLists: [],
      searchBranchLists: [],
      preBankLists: [
        {code: "0001", name: "みずほ", kana: "ﾐｽﾞﾎ"},
        {code: "0005", name: "三菱UFJ", kana: "ﾐﾂﾋﾞｼﾕｳｴﾌｼﾞｪｲ"},
        {code: "0009", name: "三井住友", kana: "ﾐﾂｲｽﾐﾄﾓ"},
        {code: "0010", name: "りそな", kana: "ﾘｿﾅ"},
        {code: "0036", name: "楽天", kana: "ﾗｸﾃﾝ"},
        {code: "0033", name: "PayPay", kana: "ペイペイ"},
        {code: "0310", name: "GMOあおぞらネット", kana: "ジ－エムオ－アオゾラネツト"},
        {code: "0038", name: "住信SBIネット", kana: "スミシンエスビ－アイネツト"},
        {code: "0035", name: "ソニー銀行", kana: "ソニ－"},
      ],
    }
  },
  async mounted() {
    let self = this
  },
  watch: {
    client(val){
      this.edit_client = _.cloneDeep(val)
    },
  },
  computed: {
  },
  methods: {
    // 処理開始
    open(client){
      this.post_branch_id = null
      this.post_account_id = null
      this.edit_client = _.cloneDeep(client)
      this.show = true
    },
    // ゆうちょ情報を反映
    submitPostData(){
      if(this.post_branch_id == null || this.post_account_id == null){
        window.storeCtl.commit("alert/setError", "フォームに数字を入力して下さい。")
        return
      }
      // 通帳番号５桁の中3桁を抽出
      let res = this.convertPostCodeToBranch(this.post_branch_id)
      this.edit_client.bank_settings.bank_id = "9900"
      this.edit_client.bank_settings.bank_name = "ﾕｳﾁｮｷﾞﾝｺｳ"
      this.edit_client.bank_settings.branch_id = res.branch_id
      this.edit_client.bank_settings.branch_name = res.branch_name
      this.edit_client.bank_settings.account_type = 1
      this.edit_client.bank_settings.account_id = this.convertPostIDToAccountNumber(this.post_account_id)
      this.edit_client.bank_settings.account_owner_name = null
    },
    // 保存する
    async submitBank(){
      let self = this
      self.$store.commit("loading/start")
      await httpClient
        .put(`/cmp/floor/clients/${self.edit_client.uid}/update_by_key.json`, {
          key: "bank_settings",
          value: self.edit_client.bank_settings,
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            window.storeCtl.commit("loading/stop")
            self.$emit("updated", res.data.data.client)
            this.show = false
            window.storeCtl.commit("alert/setSuccess", "更新しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },

    // 金融機関番号検索
    openSearch(){
      this.selectedBank = null
      this.searchBankLists = []
      this.searchBranchLists = []
      this.searchKeyword = null
      this.showSearch = true
    },
    selectBankAndBranch(){
      this.edit_client.bank_settings.bank_id = "9900"
      this.edit_client.bank_settings.bank_name = "ﾕｳﾁｮｷﾞﾝｺｳ"
      this.edit_client.bank_settings.branch_id = 11
      this.edit_client.bank_settings.branch_name = 11
      this.edit_client.bank_settings.account_type = 1
      this.edit_client.bank_settings.account_id = null
      this.edit_client.bank_settings.account_owner_name = null
      this.showSearch = false
    },
    async searchBanks(){
      let url = `https://bank.teraren.com/banks/search.json?per=20`
      const isHiragana = /^[\u3040-\u309F]+$/.test(this.searchKeyword);
      if(isHiragana){
        url += `&hira=${this.searchKeyword}`
      }else{
        url += `&name=${this.searchKeyword}`
      }
      axios.get(url)
        .then(response => {
          // レスポンス処理
          this.searchBankLists = response.data
        })
        .catch(error => {
          // エラーハンドリング
        });
    },    
    async searchBranchs(){
      let self = this
      let url = `https://bank.teraren.com/banks/${this.selectedBank.code}/branches/search.json?hira=&per=20`
      const isHiragana = /^[\u3040-\u309F]+$/.test(this.searchKeyword);
      const isNumeric = /^[0-9]+$/.test(this.searchKeyword);
      if(isHiragana){
        url += `&hira=${this.searchKeyword}`
      }else if(isNumeric){
        url += `&code=${this.searchKeyword}`
      }else{
        url += `&name=${this.searchKeyword}`
      }
      await axios.get(url)
        .then(response => {
          // レスポンス処理
          this.searchBranchLists = response.data
        })
        .catch(error => {
          // エラーハンドリング
        });
    },
    selectBank(bank){
      this.searchKeyword = null
      this.selectedBank = bank
    },
    selectBranch(branch){
      this.edit_client.bank_settings.bank_id = this.selectedBank.code
      this.edit_client.bank_settings.bank_name = this.convertToHalfWidthKana(this.selectedBank.kana)
      this.edit_client.bank_settings.branch_id = branch.code
      this.edit_client.bank_settings.branch_name = this.convertToHalfWidthKana(branch.kana)
      this.edit_client.bank_settings.account_type = 1
      this.edit_client.bank_settings.account_id = null
      this.edit_client.bank_settings.account_owner_name = null
      this.showSearch = false
    },

    // 大文字かなを半角カナに変換
    convertToHalfWidthKana(input) {
      // Unicode正規化で全角・半角の揺れを防ぐ
      input = input.normalize('NFC');

      const kanaMap = {
          "ガ": "ｶﾞ", "ギ": "ｷﾞ", "グ": "ｸﾞ", "ゲ": "ｹﾞ", "ゴ": "ｺﾞ",
          "ザ": "ｻﾞ", "ジ": "ｼﾞ", "ズ": "ｽﾞ", "ゼ": "ｾﾞ", "ゾ": "ｿﾞ",
          "ダ": "ﾀﾞ", "ヂ": "ﾁﾞ", "ヅ": "ﾂﾞ", "デ": "ﾃﾞ", "ド": "ﾄﾞ",
          "バ": "ﾊﾞ", "ビ": "ﾋﾞ", "ブ": "ﾌﾞ", "ベ": "ﾍﾞ", "ボ": "ﾎﾞ",
          "パ": "ﾊﾟ", "ピ": "ﾋﾟ", "プ": "ﾌﾟ", "ペ": "ﾍﾟ", "ポ": "ﾎﾟ",
          "ヴ": "ｳﾞ", "ヷ": "ﾜﾞ", "ヺ": "ｦﾞ",
          "ア": "ｱ", "イ": "ｲ", "ウ": "ｳ", "エ": "ｴ", "オ": "ｵ",
          "カ": "ｶ", "キ": "ｷ", "ク": "ｸ", "ケ": "ｹ", "コ": "ｺ",
          "サ": "ｻ", "シ": "ｼ", "ス": "ｽ", "セ": "ｾ", "ソ": "ｿ",
          "タ": "ﾀ", "チ": "ﾁ", "ツ": "ﾂ", "テ": "ﾃ", "ト": "ﾄ",
          "ナ": "ﾅ", "ニ": "ﾆ", "ヌ": "ﾇ", "ネ": "ﾈ", "ノ": "ﾉ",
          "ハ": "ﾊ", "ヒ": "ﾋ", "フ": "ﾌ", "ヘ": "ﾍ", "ホ": "ﾎ",
          "マ": "ﾏ", "ミ": "ﾐ", "ム": "ﾑ", "メ": "ﾒ", "モ": "ﾓ",
          "ヤ": "ﾔ", "ユ": "ﾕ", "ヨ": "ﾖ",
          "ラ": "ﾗ", "リ": "ﾘ", "ル": "ﾙ", "レ": "ﾚ", "ロ": "ﾛ",
          "ワ": "ﾜ", "ヲ": "ｦ", "ン": "ﾝ",
          "ァ": "ｧ", "ィ": "ｨ", "ゥ": "ｩ", "ェ": "ｪ", "ォ": "ｫ",
          "ッ": "ｯ", "ャ": "ｬ", "ュ": "ｭ", "ョ": "ｮ",
          "。": "｡", "、": "､", "ー": "ｰ", "「": "｢", "」": "｣", "・": "･",
          "　": " ", "－": "-",
      };

      // マッピングを使用して変換
      return input.split("").map(char => kanaMap[char] || char).join("");
    },

    // 通帳記号を支店ID,支店名に変換
    convertPostCodeToBranch(code) {
      // 中3桁を取得
      const branch_id = code.slice(1, 4);
      // 数字を半角カナに対応付け
      const kanaMap = {
        "0": "ｾﾞﾛ",
        "1": "ｲﾁ",
        "2": "ﾆ",
        "3": "ｻﾝ",
        "4": "ﾖﾝ",
        "5": "ｺﾞ",
        "6": "ﾛｸ",
        "7": "ﾅﾅ",
        "8": "ﾊﾁ",
        "9": "ｷｭｳ"
      };
      // 中3桁を半角カナに変換
      let branch_name = "";
      for (const char of branch_id) {
        branch_name += kanaMap[char] || char; // 数字以外はそのまま
      }
      return {branch_id, branch_name};
    },
    // 
    convertPostIDToAccountNumber(passbookNumber) {
      // 下一桁を削除
      const truncated = passbookNumber.slice(0, -1);
      // 右埋めで7桁にし、空白部分を0埋め
      const accountNumber = truncated.padStart(7, '0');
      return accountNumber;
    }
  },
}
