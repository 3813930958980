
import Common from '~/mixins/common'
import Masters from '~/mixins/masters'
import _ from 'lodash';
import { taxRateOptions } from '~/utils/models'

export default {
  mixins: [Common,Masters],
  components: {
  },
  props: {
    useSubscriptions: {
      type: Boolean,
      default: false
    },
    cartItems: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      showItemsModal: false,
      currentCategories: [],
      editCartItems: [],
      taxRateOptions: taxRateOptions,
    }
  },
  async mounted() {
    let self = this
    this.editCartItems = _.cloneDeep(self.cartItems)
  },
  watch: {
    cartItems: {
      handler: function (val) {
        this.editCartItems = _.cloneDeep(val)
      },
      deep: true
    },
  },
  computed: {
    // item
    itemsModalTitle(){
      let size = 0
      let price = 0
      this.currentCategories.forEach(cat => {
        cat.items.forEach(it => {
          size += it.count
          price += it.count * it.price
        })
      })
      return `${size}個 / ${price}円`
    },
  },
  methods: {
    //// 商品　//////////////////////
    openItems(){
      this.setUpCategories()
      this.showItemsModal = true
    },
    addItem(item){
      console.log("addItem", item)
      item.count += 1
    },
    addDiscount(item, rate){
      this.$bvModal.msgBoxConfirm('オプションの割引を追加してよろしいですか？', {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          const price = parseInt(item.price * rate) * -1
          const adjustedItem = {
            is_subscription: false,
            id: item.id,
            item_category_id: item.item_category_id,
            name: `${item.name}(初回割引)`,
            description: item.description,
            price: price,
            count: item.count,
            sub_total: price * item.count,
            tax_rate: item.tax_rate,
          }
          this.editCartItems.push(adjustedItem)
          this.cartUpdate()
        }
      })
    },
    addItemsToCart(){
      let selectedItems = []
      this.currentCategories.forEach(cat => {
        cat.items.forEach(it => {
          if(it.count > 0){
            it.is_subscription = false
            it.sub_total = it.price * it.count
            // いらないItem属性入らないように調整
            const adjustedItem = {
              is_subscription: true,
              id: it.id,
              item_category_id: it.item_category_id,
              name: it.name,
              description: it.description,
              price: it.price,
              count: it.count,
              sub_total: it.price * it.count,
              tax_rate: it.tax_rate,
            }
            selectedItems.push(adjustedItem)
          }
        })
      })
      this.editCartItems = _.concat(this.editCartItems, selectedItems)
      this.cartUpdate()
      this.showItemsModal = false
    },
    deleteItem(index){
      this.editCartItems.splice(index, 1);
      this.cartUpdate()
    },
    changeOrder(){
      this.cartUpdate()
    },

    cartUpdate(){
      // this.editCartItemsの中身をそれぞれ個数とPriceに合わせて小計を更新
      this.editCartItems.forEach(it => {
        it.sub_total = it.price * it.count
      })
      this.$emit("cartUpdate", this.editCartItems)
    },

    setUpCategories(){
      let self = this
      // setup items
      let base_items = _.cloneDeep(self.items)
      base_items.forEach(it => {
        it.count = 0
        it.is_custom = false
      })
      let currentCategories = _.cloneDeep(self.item_categories)
      currentCategories.forEach(cat => {
        cat.items = _.filter(base_items, it => {
          return it.item_category_id === cat.id
        })
      })
      currentCategories.push({
        name: "無分類",
        items: [{
          id: null,
          name: "カスタム",
          name_inner: "カスタム",
          price: 0,
          tax_rate: 10,
          manage_stock: false,
          is_custom: true,
          count: 0,
          item_category_id: null,
        }],
      })
      this.currentCategories = currentCategories
    },
  },
}
