export default function ({ route, store, redirect }) {
  if (!store.getters['auth/isLogin']) {
    console.log('in logout')
    return redirect('/login/')
  }

  // トライアル状態ならトライアル画面に飛ばす
  if (
    store.state.auth.company &&
    store.state.auth.company.required_card &&
    !store.state.auth.company.has_card
  ) {
    if (!route.path.includes('/settings/trial/'))
      return redirect('/settings/trial/')
  }
}
