
import Common from '~/mixins/common'
import Masters from '~/mixins/masters'
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import dayjs from 'dayjs';

export default {
  mixins: [Common,Masters],
  components: {
  },
  props: {
    clientSelectable: {type: Boolean, default: true},
  },
  data() {
    return {
      // modal
      showModal: false,
      edit_client: null,

      // ticket
      edit_course_uid: null,
      name: "",
      price: 0,
      ticket_number: 1,
      add_month: 0,
      expire_days: 0,
      use_from: null,
      use_to: null,
      able_menu_ids: [],

      // 月回数制の付与月
      add_month: 0,
      months: [
        {text: "今月", value: 0},
        {text: "来月", value: 1},
        {text: "再来月", value: 2},
      ]
    }
  },
  async mounted() {
  },
  computed: {
    currentFilterdCourses(){
      let courses = _.filter(this.companyUsableCourses, c => {
        if(c.use_new_version){
          return this.edit_client && c.reserve_type === this.edit_client.reserve_type
        }else{
          return true
        }
      })
      return courses
    },
  },
  methods: {
    //// 支払い　//////////////////////
    ticketNew(client){
      console.log("paymentNew", client)
      // 初期データ
      if(client){
        this.edit_client = _.cloneDeep(client)
        this.edit_client_id = this.edit_client.id
      }else{
        this.edit_client = null
        this.edit_client_id = null
        this.getClients()
      }
      this.$store.dispatch("company/getCourses")

      // 初期化
      this.edit_course_uid = null
      this.name = ""
      this.price = 0
      this.ticket_number = 1
      this.add_month = 0
      this.expire_days = 0
      this.use_from = dayjs().format("YYYY-MM-DD")
      this.use_to = null
      this.able_menu_ids = []
      this.changeClient()
      this.showModal = true
    },
    changeClient(){
      if(this.clientSelectable) this.edit_client = _.find(this.clients, ["id", this.edit_client_id])
      // クライアントに紐づくチケットを表示
      if(this.edit_client){
        let selectedCourse = _.find(this.currentFilterdCourses, ["id", this.edit_client.course_id])
        if(selectedCourse){
          this.edit_course_uid = selectedCourse.uid
          this.changeCourse()
        }else{
          this.edit_course_uid = null
          this.name = ""
          this.price = 0
          this.ticket_number = 1
          this.expire_days = 1
          this.able_menu_ids = []
          this.use_from = dayjs().format("YYYY-MM-DD")
          this.use_to = null
        }
      }
    },
    changeCourse(){
      let course = _.find(this.currentFilterdCourses, ["uid", this.edit_course_uid])
      if(course){
        this.name = course.name
        this.price = course.price
        this.ticket_number = course.ticket_number
        this.expire_days = course.expire_days
        this.able_menu_ids = course.able_menu_ids
        if(!this.use_from) this.use_from = dayjs().format("YYYY-MM-DD")
        this.changeTicketLimit()
      }
    },
    changeTicketLimit(){
      console.log("changeTicketLimit")
      this.use_to = dayjs(this.use_from).subtract(1,"d").add(this.expire_days, "d").format("YYYY-MM-DD")
    },
    // チケット付与
    async addTicket(){
      let self = this
      // 支払い
      self.$store.commit("loading/start", true)
      await httpClient
        .post(`/cmp/floor/ticket_sets.json`, {
          client_id: self.edit_client_id,
          course_uid: self.edit_course_uid,
          name: self.name,
          price: self.price,
          ticket_number: self.ticket_number,
          add_month: self.add_month,
          expire_days: self.expire_days,
          use_from: self.use_from,
          use_to: self.use_to,
          able_menu_ids: self.able_menu_ids,
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setSuccess", "チケットを付与しました")
            self.showModal = false
            this.$emit("updated")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
  },
}
