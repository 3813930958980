
import _ from 'lodash';
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  data() {
    return {
      showSettingModal: false,
      version: !!process.env.VERSION ? Number(process.env.VERSION) : 0,
      api_version: process.env.API_VERSION,
    }
  },
  methods: {
    open(){
      this.showSettingModal = true
    },
    getLocalStrage(){
      let total = 0;
      for (let key in window.localStorage) {
          if (window.localStorage.hasOwnProperty(key)) {
              total += ((window.localStorage[key].length + key.length) * 2); // 1文字 = 2バイト
          }
      }
      const totalMB = total / (1024 * 1024); // バイトをMBに変換
      return `${totalMB.toFixed(2)}MB`
    },
  }
}
