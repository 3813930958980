
import Common from '~/mixins/common'
import Calucurator from '~/components/common/cl_payment/Calucurator.vue'
import _ from 'lodash';

export default {
  mixins: [Common],
  components: {
    Calucurator,
  },
  props: {
  },
  watch: {
  },
  data() {
    return {
      canOnline: false,
      showCalculateModal: false,
      item_price_sum: 0,
      azukariPrice: 0,
      selectedPaymentMethod: null,

      // 支払い回数モーダル
      showComfirmModal: false,
      // 3、5、6、10、12、15、18、20、24
      card_payment_number: 1,
      card_payment_numberOptions: [
        { text: '1回', value: 1 },
        { text: '3回', value: 3 },
        { text: '5回', value: 5 },
        { text: '6回', value: 6 },
        { text: '10回', value: 10 },
        { text: '12回', value: 12 },
        { text: '15回', value: 15 },
        { text: '18回', value: 18 },
        { text: '20回', value: 20 },
        { text: '24回', value: 24 },
      ],
    }
  },
  async mounted() {
  },
  computed: {
    //　会計モーダル
    manSet(){
      let man = parseInt(this.item_price_sum / 10000)
      let amari = this.item_price_sum % 10000
      let result_man = amari > 0 ? man + 1 : man
      return result_man * 10000
    },
    senSet(){
      let sen = parseInt(this.item_price_sum / 1000)
      let amari = this.item_price_sum % 1000
      let result_sen = amari > 0 ? sen + 1 : sen
      return result_sen * 1000
    },
    otsuriPrice(){
      return this.azukariPrice - this.item_price_sum
    },
    filteredAzukariPrice(){
      if(this.azukariPrice === null) return 0
      return this.azukariPrice
    }
  },
  methods: {
    openModal(item_price_sum, canOnline){
      this.canOnline = canOnline
      // 店舗設定でカードNGならFalse
      if(this.company.allow_cl_payment_in_cmp === false) this.canOnline = false
      this.item_price_sum = item_price_sum
      if(this.canOnline){
        this.changePaymentMethod({id: null, is_cash: false})
      }else{
        this.changePaymentMethod(this.cashier_payment_methods[0])
      }
      this.showCalculateModal = true
    },
    closeModal(){
      this.showCalculateModal = false
    },
    changePaymentMethod(pm){
      this.selectedPaymentMethod = pm
      if(this.selectedPaymentMethod.is_cash){
        this.azukariPrice = 0
      }else{
        this.azukariPrice = _.cloneDeep(this.item_price_sum)
      }
    },
    changeAzukariNumber(number){
      if(!this.selectedPaymentMethod.is_cash) return
      this.azukariPrice = number
    },
    addManSet(){
      if(!this.selectedPaymentMethod.is_cash) return
      this.azukariPrice = this.manSet
    },
    addSenSet(){
      if(!this.selectedPaymentMethod.is_cash) return
      this.azukariPrice = this.senSet
    },
    addZankin(){
      if(!this.selectedPaymentMethod.is_cash) return
      this.azukariPrice = this.item_price_sum
    },

    // 支払い確認 -----------------------------------------------------
    confirmPay(){
      // 確認モーダル立ち上げる
      this.card_payment_number = 1
      this.showComfirmModal = true
    },

    // 支払い実行 -----------------------------------------------------
    submitPay(){
      console.log("submitPay")
      this.showComfirmModal = false
      this.$emit('submitPay', {
        payment_method_id: this.selectedPaymentMethod.id,
        azukari_price: this.azukariPrice,
        otsuri_price: this.otsuriPrice,
        payment_with_online: this.selectedPaymentMethod.id === null ? true : false,
        card_payment_number: this.card_payment_number,
      })
    },

  },
}
