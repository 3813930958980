
import _ from 'lodash';
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  data() {
    return {
      showSettingModal: false,
      version: !!process.env.VERSION ? Number(process.env.VERSION) : 0,
      api_version: process.env.API_VERSION,
    }
  },
  methods: {
    open(){
      this.showSettingModal = true
    },
  }
}
