import { httpClient } from '~/utils/axios'
import _ from 'lodash'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(minMax);

const getDefaultState = () => {
  return {
    // contact
    firstContact: false,

    // auth
    authToken: null,
    headquarter: null,
    company: null,
    staff: null,
    settings: [],
    new_alert_size: 0,

    // push
    lastUpdatedAt: null,
    oneSignalDeviseToken: null,

    // 端末固有設定
    latest_view_clients: [], //
    selectedClient: null,
    calendar_ids: [],
    calendar_view_type: 'resourceTimeGridFourDay',
    calendar_only_business_hours: true,
    calendar_show_extra_days: true,
    calendar_start_on_sunday: true,
    calendar_resource_mode: 'calendar',
    calendar_duration: 15,
    reverve_check_has_ticket: false,
    reserve_push: false,
    showEventSetDetail: false,

    latest_clients: [], // old

  }
}

const shouldUpdate = (apiUpdate, localUpdate) => {
  if (localUpdate === null) return false;
  if (apiUpdate === null) return false;

  // 更新日時を比較して、APIの方が新しければ更新が必要
  return dayjs(apiUpdate).isAfter(dayjs(localUpdate));
};

// 更新が必要なAPIリクエストを非同期で順番に実行
async function updateMastersSequentially(mu, lu, force = false) {
  const requests = [];
  // 条件に応じて実行する関数を配列に追加
  if (force || !lu || shouldUpdate(mu.calendars, lu.calendars)) requests.push(() => window.storeCtl.dispatch('company/getCalendars'));
  if (force || !lu || shouldUpdate(mu.resources, lu.resources)) requests.push(() => window.storeCtl.dispatch('company/getResources'));
  if (force || !lu || shouldUpdate(mu.courses, lu.courses)) requests.push(() => window.storeCtl.dispatch('company/getCourses'));
  if (force || !lu || shouldUpdate(mu.menus, lu.menus)) requests.push(() => window.storeCtl.dispatch('company/getMenus'));
  if (force || !lu || shouldUpdate(mu.payment_methods, lu.payment_methods)) requests.push(() => window.storeCtl.dispatch('company/getPaymentMethods'));
  if (force || !lu || shouldUpdate(mu.item_categoreis, lu.item_categoreis)) requests.push(() => window.storeCtl.dispatch('company/getItemCategories'));
  if (force || !lu || shouldUpdate(mu.items, lu.items)) requests.push(() => window.storeCtl.dispatch('company/getItems'));
  if (force || !lu || shouldUpdate(mu.training_categories, lu.training_categories)) requests.push(() => window.storeCtl.dispatch('company/getTrainingCategories'));
  if (force || !lu || shouldUpdate(mu.training_events, lu.training_events)) requests.push(() => window.storeCtl.dispatch('company/getTrainingEvents'));
  if (force || !lu || shouldUpdate(mu.training_machines, lu.training_machines)) requests.push(() => window.storeCtl.dispatch('company/getTrainingMachines'));
  if (force || !lu || shouldUpdate(mu.training_muscles, lu.training_muscles)) requests.push(() => window.storeCtl.dispatch('company/getTrainingMuscles'));
  if (force || !lu || shouldUpdate(mu.time_zones, lu.time_zones)) requests.push(() => window.storeCtl.dispatch('company/getTimeZones'));
  if (force || !lu || shouldUpdate(mu.surveys, lu.surveys)) requests.push(() => window.storeCtl.dispatch('company/getSurveys'));
  if (force || !lu || shouldUpdate(mu.company_groups, lu.company_groups)) requests.push(() => window.storeCtl.dispatch('company/getCompanyGroups'));
  if (force || !lu || shouldUpdate(mu.reserve_settings, lu.reserve_settings)) requests.push(() => window.storeCtl.dispatch('company/getReserveSettings'));
  if (force || !lu || shouldUpdate(mu.companies, lu.companies)) requests.push(() => window.storeCtl.dispatch('company/getCompanies'));

  // 非同期リクエストを順番に実行
  for (const request of requests) {
    await request(); // 各リクエストを待機してから次へ進む
  }
}

export const state = () => getDefaultState()
export const mutations = {
  // contact
  firstContactDone(state, payload) {
    state.firstContact = true
  },

  // auth
  setAuthToken(state, auth_token) {
    state.authToken = auth_token
  },
  setHeadquarter(state, headquarter) {
    state.headquarter = headquarter
  },
  setCompany(state, company) {
    state.company = company
  },
  setStaff(state, staff) {
    state.staff = staff
  },
  setSettings(state, settings) {
    state.settings = settings
  },
  setLastUpdatedAt(state, payload) {
    state.lastUpdatedAt = payload
  },
  setNewAlertSize(state, new_alert_size) {
    state.new_alert_size = new_alert_size
  },
  setOneSignalDeviseToken(state, oneSignalDeviseToken) {
    state.oneSignalDeviseToken = oneSignalDeviseToken
  },

  // 端末固有設定
  setClient(state, client) {
    console.log('setClient')
    state.selectedClient = client

    // リスト追加
    let new_clients = _.filter(state.latest_view_clients, cli => {
      return cli.id !== client.id
    })
    new_clients.unshift({
      uid: client.uid,
      name: client.name
    })
    // 20超えたら銭湯削除
    if (new_clients.length > 10) new_clients.splice(-1)
    state.latest_new_clients = new_clients
  },
  clearLatestClients(state) {
    state.latest_clients = []
  },
  setCalendarIds(state, calendar_ids) {
    state.calendar_ids = calendar_ids
  },
  setCalendarViewType(state, calendar_view_type) {
    state.calendar_view_type = calendar_view_type
  },
  setCalendarOnlyBusinessHours(state, calendar_only_business_hours) {
    state.calendar_only_business_hours = calendar_only_business_hours
  },
  setCalendarShowExtraDays(state, calendar_show_extra_days) {
    state.calendar_show_extra_days = calendar_show_extra_days
  },
  setCalendarStartOnSunday(state, calendar_start_on_sunday) {
    state.calendar_start_on_sunday = calendar_start_on_sunday
  },
  setCalendarResourceMode(state, calendar_resource_mode) {
    state.calendar_resource_mode = calendar_resource_mode
  },
  setCalendarDuration(state, calendar_duration) {
    state.calendar_duration = calendar_duration
  },
  setReverveCheckHasTicket(state, reverve_check_has_ticket) {
    state.reverve_check_has_ticket = reverve_check_has_ticket
  },
  setReservePush(state, reserve_push) {
    state.reserve_push = reserve_push
  },
  setShowEventSetDetail(state, showEventSetDetail) {
    state.showEventSetDetail = showEventSetDetail
  },

  // logout
  logout(state) {
    $nuxt.$router.push(`/login/`)
    Object.assign(state, getDefaultState())
    // firebase disconnect
    window.storeCtl.dispatch('fireStore/destroy')
    window.storeCtl.commit('company/reset')
    window.storeCtl.commit('devise/reset')
  },

  // update old
  updateOldDate(state) {
    state.latest_clients = []
  }
}
export const getters = {
  isLogin(state) {
    return !!state.authToken
  },
  isOwner(state) {
    return state.staff.id === state.headquarter.owner_id
  }
}

// 戻り地はisBeforeRequest
export const actions = {
  async refreshToken(context) {
    await httpClient
      .post('/cmp/account/refresh_token.json')
      .then(async res => {
        if (res.data.status === 'success') {
          if(res.data.data.is_admin === false) context.commit('setAuthToken', res.data.data.auth_token)
          console.log("refreshToken",res.data.data.expire_at)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
  },
  // マスタを矯正Update
  async mstForceUpdate(context) {
    window.storeCtl.dispatch('auth/getStaff')
    window.storeCtl.dispatch('auth/getCompany')
    updateMastersSequentially({}, {}, true)
  },
  // 更新状況を見てマスタ更新
  async mstCheckUpdate(context) {
    await httpClient
      .get('/cmp/has_update.json')
      .then(async res => {
        if (res.data.status === 'success') {
          // alert size
          context.commit('setNewAlertSize', res.data.data.new_alert_size)
          
          // 最終更新日時
          let lastUpdatedAt = context.state.lastUpdatedAt;
          // それぞれの更新日時が lastUpdatedAt より新しいかをチェック
          let isNewer = lastUpdatedAt === null ||
                      dayjs(res.data.headquarter_updated_at).isAfter(dayjs(lastUpdatedAt)) ||
                      dayjs(res.data.company_updated_at).isAfter(dayjs(lastUpdatedAt)) ||
                      dayjs(res.data.staff_updated_at).isAfter(dayjs(lastUpdatedAt));
          // 更新されている場合
          if (isNewer) {
            // 各更新日時を dayjs で処理
            let updateTimes = [
              dayjs(res.data.headquarter_updated_at),
              dayjs(res.data.company_updated_at),
              dayjs(res.data.staff_updated_at)
            ];
            // 最も古い日時を取得
            let oldestUpdate = dayjs.min(updateTimes);
            context.commit('setLastUpdatedAt', oldestUpdate)
            // ここで一度マスターをすべて取得
            window.storeCtl.dispatch('auth/getStaff')
            window.storeCtl.dispatch('auth/getCompany')
          }

          // マスター更新必要性チェック
          const mu = res.data.data.master_updates
          const lu = window.storeCtl.state.company.local_master_updates
          updateMastersSequentially(mu, lu)
          // FIXME: どれかのAPIコケてもマスタ更新されない気がする
          window.storeCtl.commit('company/setMasterUpdates', mu)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit('loading/stop')
      })
  },

  //// push
  // ログインしていて
  // LocalStrage取れていて
  // StoreとLocalstrageの値が違う
  // サーバー同期処理
  async updateOneSignalDeviseToken(context) {
    const onesignal_user_id = localStorage.getItem('onesignal_user_id')
    if (
      onesignal_user_id &&
      window.storeCtl.state.auth.oneSignalDeviseToken !== onesignal_user_id
    ) {
      await httpClient
        .post('/cmp/account/update_onesignal_device_token.json', {
          onesignal_device_token: onesignal_user_id
        })
        .then(res => {
          if (res.data.status === 'success') {
            window.storeCtl.commit(
              'auth/setOneSignalDeviseToken',
              onesignal_user_id
            )
          } else {
            window.storeCtl.commit('alert/setError', res.data.message)
          }
        })
    }
  },

  async getClient(context, uid) {
    await httpClient
      .get(`/cmp/floor/clients/${uid}.json`)
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setClient', res.data.data.client)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit('loading/stop')
      })
  },

  async getStaff(context) {
    await httpClient
      .get('/cmp/account/staff.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setStaff', res.data.data.staff)
          window.storeCtl.commit("devise/setAbleCompanies", res.data.data.able_companies)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getCompany(context) {
    await httpClient
      .get('/cmp/account/company.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setHeadquarter', res.data.data.headquarter)
          context.commit('setCompany', res.data.data.company)
          context.commit('setSettings', res.data.data.settings)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
}
