
import Common from '~/mixins/common'
import _ from 'lodash';

export default {
  mixins: [Common],
  components: {
  },
  props: {
    client: Object,
  },
  data() {
    return {
      accountTypeOptions: [
        { value: 1, text: '普通' },
        { value: 2, text: '当座' },
        { value: 3, text: '貯蓄' },
      ],
    }
  },
  async mounted() {
    let self = this
  },
  watch: {
    client(val){
    },
  },
  computed: {
    account_type_name(){
      const account_type = _.find(this.accountTypeOptions, { value: this.client.bank_settings.account_type })
      return account_type ? account_type.text : ''
    },

  },
  methods: {
  },
}
