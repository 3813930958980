
import Common from '~/mixins/common'
import _ from 'lodash';
import UidCopyPart from '~/components/util/UidCopyPart'

export default {
  mixins: [Common],
  components: {
    UidCopyPart,
  },
  props: {
    cl_payment: Object,
  },
  data() {
    return {
    }
  },
  async mounted() {
    let self = this
  },
  watch: {
  },
  computed: {
  },
  methods: {
  },
}
